import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { firebase } from "../Firebase";

import { getUserRoles } from "../Data";

type ContextProps = {
  user: firebase.User | null;
  roles: [];
  authenticated: boolean;
  setUser: any;
  loadingAuthState: boolean;
};

export const AuthContext = React.createContext<Partial<ContextProps>>({});

export const AuthProvider = ({ children }: any) => {
  const history = useHistory();
  const [user, setUser] = useState(null as firebase.User | null);
  const [roles, setRoles] = useState([]);
  const [loadingAuthState, setLoadingAuthState] = useState(true);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user: any) => {
      let roles = [];

      if (user) {
        roles = await getUserRoles(user.uid);
      }

      setUser({ ...user, roles });
      setLoadingAuthState(false);
      if (user === null) {
        history.push(`/login`);
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        roles,
        authenticated: user !== null,
        setUser,
        loadingAuthState,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
