import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";

import { Button } from "./elements";

const LogOutBtn = () => {
  const history = useHistory();

  const handleClick = (event: any) => {
    event.preventDefault();

    firebase
      .auth()
      .signOut()
      .then((res) => {
        history.push("/login");
      });
  };

  return <Button onClick={handleClick}>Kirjaudu ulos</Button>;
};

export default LogOutBtn;
