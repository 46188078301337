import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import { AuthContext } from "../_context/auth-context";
import Analysis from "../components/Analysis";
import { Button, H1, H2, P, TeamBody } from "../components/elements";
import LoadingIndicator from "../components/LoadingIndicator";
import LogOutBtn from "../components/Logout";
import { getPendingRequests, getUserRequests } from "../Data";
import { isUserAdmin } from "../utils";

const Login = () => {
  const history = useHistory();
  const { loadingAuthState, user } = useContext(AuthContext);
  const [requests, setRequests] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const adminUser = isUserAdmin(user);

      setIsAdmin(adminUser);

      let result;
      if (adminUser) {
        setLoadingRequests(true);
        result = await getPendingRequests();
      } else {
        result = await getUserRequests(user.uid);
      }
      setLoadingRequests(false);
      setRequests(result);
    };

    if (user && user.uid) {
      fetchData();
    }
  }, [user]);

  if (loadingAuthState || loadingRequests) {
    return <LoadingIndicator />;
  }

  const sortedRequests = requests.sort(
    (a, b) => b.created.seconds - a.created.seconds
  );

  return (
    <div>
      <TeamBody />

      {!isAdmin && (
        <div>
          <H1>Svingi hukassa?</H1>
          <P>
            Lataa video svingistäsi ja saat ammattivalmentajan analyysin sekä
            toimivimmat vinkit svingisi parantamiseksi.
          </P>
          <Button onClick={(e) => history.push("/request-analysis")}>
            Tilaa svingianalyysi
          </Button>
        </div>
      )}

      <div>
        <H2>Svingianalyysit</H2>
        {isAdmin && sortedRequests.length === 0 && (
          <p>Ei analysoitavia svingejä - kaikki svingit on analysoitu</p>
        )}
        {sortedRequests.map((item) => (
          <Analysis key={item.id} {...item} />
        ))}
      </div>
      <br />
      <br />
      <LogOutBtn />
    </div>
  );
};
export default Login;
