import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Card, CardBody, CardFooter } from "shards-react";
import { AuthContext } from "../_context/auth-context";
import { isUserAdmin, formatDate } from "../utils";

const josefin = `"Josefin Sans", sans-serif;`;

const AnalysisCard = styled(Card)`
  font-family: ${josefin};
  font-size: 1.25rem;
  color: #666;
  margin-bottom: 2rem;
  cursor: pointer;
`;

const Analysis = ({ id, ready, created, email }) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const adminUser = isUserAdmin(user);

  const handleClick = (event: any) => {
    event.preventDefault();
    history.push(`/request-analysis/${id}`);
  };

  return (
    <AnalysisCard onClick={handleClick}>
      <CardBody>
        <div>
          {formatDate(created)}
          {adminUser && email && " " + email}
        </div>
      </CardBody>
      <CardFooter>
        {ready === false && "Odottaa analyysiä"}
        {ready === true && "Analyysi valmis"}
      </CardFooter>
    </AnalysisCard>
  );
};

export default Analysis;
