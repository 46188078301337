import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Fade, Form } from "shards-react";

import { AuthContext } from "../_context/auth-context";
import { Button, FormTextarea, H1, H2, P } from "../components/elements";
import FormRow from "../components/FormRow";
import VideoLoader from "../components/Video";
import { writeRequest } from "../Data";

type RequestAnalysis = {
  values: Object;
  media: Object;
  setError: Function;
  handleChange: Function;
  setMedia: Function;
  editing: Boolean;
};

const RequestAnalysis = ({
  values,
  media,
  setError,
  setMedia,
  handleChange,
  editing,
}) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const request = {
      ...values,
      media,
      user: user.uid,
      email: user.email,
      created: firebase.firestore.Timestamp.now(),
    };

    writeRequest(request)
      .then(() => history.push(`/`))
      .catch((error) => setError(error));
  };

  const addMedia = (file) => setMedia({ ...media, [file.tag]: file });

  return (
    <Form onSubmit={handleSubmit}>
      <H1>Svingianalyysi</H1>
      {values.ready === true && (
        <div>
          <FormRow>
            <H2>Videoanalyysi{media.analysis2 && "t"}</H2>
            <VideoLoader
              tag="side"
              video={media.analysis1}
              onSuccess={addMedia}
              onError={setError}
              editable={false}
            />
          </FormRow>
          {media.analysis2 && (
            <FormRow>
              <VideoLoader
                tag="side"
                video={media.analysis2}
                onSuccess={addMedia}
                onError={setError}
                editable={false}
              />
            </FormRow>
          )}
          {media.analysis3 && (
            <FormRow>
              <VideoLoader
                tag="side"
                video={media.analysis3}
                onSuccess={addMedia}
                onError={setError}
                editable={false}
              />
            </FormRow>
          )}
          {media.analysis4 && (
            <FormRow>
              <VideoLoader
                tag="side"
                video={media.analysis4}
                onSuccess={addMedia}
                onError={setError}
                editable={false}
              />
            </FormRow>
          )}
          {media.analysis5 && (
            <FormRow>
              <VideoLoader
                tag="side"
                video={media.analysis5}
                onSuccess={addMedia}
                onError={setError}
                editable={false}
              />
            </FormRow>
          )}
          {values.analysis && (
            <FormRow>
              <div>
                <H2>Analyysin kuvaus</H2>
                <p>{values.analysis}</p>
              </div>
            </FormRow>
          )}
          <H1>Svingi</H1>
        </div>
      )}
      <FormRow>
        <H2>Video edestä</H2>
        <VideoLoader
          tag="front"
          video={media.front}
          onSuccess={addMedia}
          onError={setError}
        />
      </FormRow>
      <FormRow>
        <H2>Video sivusta</H2>
        <VideoLoader
          tag="side"
          video={media.side}
          onSuccess={addMedia}
          onError={setError}
        />
      </FormRow>

      {!editing && (
        <div>
          <FormRow>
            <H2>Kuvaile ongelmaa</H2>
            <FormTextarea
              name="description"
              onChange={handleChange}
              value={values.description}
              placeholder="Kirjoita omin sanoin"
              rows="5"
            />
          </FormRow>
          <FormRow>
            <Button>Lähetä svingi opettajalle</Button>
          </FormRow>
        </div>
      )}
      {editing && (
        <FormRow>
          <div>
            <H2>Ongelma</H2>
            <p>{values.description}</p>
          </div>
        </FormRow>
      )}
    </Form>
  );
};
export default RequestAnalysis;
