import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import firebaseConfig from "./config";

firebase.initializeApp(firebaseConfig);

var db = firebase.firestore();

var storage = firebase.storage();

export { firebase, db, storage };
