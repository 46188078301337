import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import * as ReactDOM from "react-dom";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import { Col, Container } from "shards-react";

import { AuthProvider } from "./_context/auth-context";
import { Analysis, Home, Login } from "./pages";

import "./static/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "firebase/auth";
import "shards-ui/dist/css/shards.min.css";

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn:
      "https://1e14d8cae1064d839163122c79626d31@o120783.ingest.sentry.io/5459644",
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
  });
}

const App = () => {
  return (
    <Router>
      <Container>
        <Col sm="12" lg={{ size: 6, offset: 3 }}>
          <AuthProvider>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route path="/request-analysis/:id?" component={Analysis} />
          </AuthProvider>
        </Col>
      </Container>
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
