import firebase from "firebase/app";

import { storage, db } from "./Firebase";
import "firebase/auth";

const requests = db.collection("requests");
const roles = db.collection("roles");
const storageRef = storage.ref();

const getData = (result) => result.data();

const writeRequest = (data) => requests.add(data);
const editRequest = (id, data) => requests.doc(id).update(data);

const uploadFile = (file) =>
  storageRef.child(`${Date.now()}-${file.name}`).put(file);

const getUserRequests = (userId) => {
  return requests
    .where("user", "==", userId)
    .get()
    .then(({ docs }) => docs.map((x) => ({ ...getData(x), id: x.id })));
};

const getPendingRequests = () => {
  return requests
    .where("ready", "==", false)
    .get()
    .then(({ docs }) => docs.map((x) => ({ ...getData(x), id: x.id })));
};

const getRequestById = (id) =>
  requests
    .doc(id)
    .get()
    .then((result) => getData(result));

const getUserRoles = (userId) => {
  return roles
    .where("user", "==", userId)
    .get()
    .then(({ docs }) => docs.map(getData));
};

const getFileUrl = (filename) => storageRef.child(filename).getDownloadURL();

export {
  writeRequest,
  editRequest,
  uploadFile,
  getUserRequests,
  getPendingRequests,
  getRequestById,
  getUserRoles,
  getFileUrl,
};
