import firebase from "firebase/app";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Col, Container, Form } from "shards-react";

import { AuthContext } from "../_context/auth-context";
import { Button, FormInput, H1, LoginBody } from "../components/elements";
import FormRow from "../components/FormRow";

interface UserData {
  email: string;
  password: string;
}

const Login = () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const [values, setValues] = useState({
    email: "",
    password: "",
  } as UserData);
  const [error, setError] = useState(null);

  const handleChange = (event: any) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        firebase
          .auth()
          .signInWithEmailAndPassword(values.email, values.password)
          .then((res) => {
            authContext.setUser(res);
            history.push("/");
          })
          .catch((e) => {
            setError(e.message);
          });
      });
  };

  return (
    <div>
      <LoginBody />
      <H1 color="#000">Your Golf Studio</H1>
      {error !== null && <Alert theme="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <FormInput
            placeholder="Sähköpostiosoite"
            name="email"
            onChange={handleChange}
            value={values.email}
          />
        </FormRow>
        <FormRow>
          <FormInput
            placeholder="Salasana"
            type="password"
            name="password"
            onChange={handleChange}
            value={values.password}
          />
        </FormRow>
        <FormRow>
          <Button>Kirjaudu sisään</Button>
        </FormRow>
      </Form>
    </div>
  );
};
export default Login;
