let firebaseConfig = {
  apiKey: "AIzaSyCPXmaUfGQ2phg3nlw_ftMkbOI8uP0ktJE",
  authDomain: "golf-studio-2f9b0.firebaseapp.com",
  databaseURL: "https://golf-studio-2f9b0.firebaseio.com",
  projectId: "golf-studio-2f9b0",
  storageBucket: "golf-studio-2f9b0.appspot.com",
  messagingSenderId: "1013915354654",
  appId: "1:1013915354654:web:73e8bddc5dd8d1eea7100f",
  measurementId: "G-E092GHQN8B",
};

export default firebaseConfig;
