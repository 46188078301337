import React, { useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import styled from "styled-components";

import { Button } from "../components/elements";
import LoadingIndicator from "../components/LoadingIndicator";
import { getFileUrl, uploadFile } from "../Data";
import frontImg from "../static/img/front.jpg";
import sideImg from "../static/img/side.jpg";

type VideoProps = {
  tag: string;
  video: { name: string };
  onSuccess: Function;
  onError: Function;
  editable: Boolean;
};

const bgs = { side: sideImg, front: frontImg, analysis: frontImg };

const VideoLoader = ({
  tag,
  video,
  onSuccess,
  onError,
  editable,
}: VideoProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone();
  const [loading, setLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const fetchVideoUrl = async (name) => {
    const result = await getFileUrl(name);
    setVideoUrl(result);
  };

  useEffect(() => {
    if (video.name) {
      fetchVideoUrl(video.name);
    }
  }, [video]);

  const VideoContainer = styled.div`
    display: block;
    width: 100%;
    max-width: 640px;
    max-height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 320px;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
    background: ${videoUrl !== null
      ? "transparent"
      : "url(" + bgs[tag] + ") center -50px no-repeat;"};
  `;

  const onImageDrop = ([file]) => {
    setLoading(true);
    uploadFile(file)
      .then(function ({ metadata }) {
        setLoading(false);
        fetchVideoUrl(metadata.name);
        onSuccess({ name: metadata.name, tag });
      })
      .catch(function (error) {
        setLoading(false);
        console.log(error);
        onError("Error adding file: ", error);
      });
  };

  return (
    <VideoContainer>
      {loading && <LoadingIndicator />}
      {loading && <h2 color="#fff">Ladataan videota...</h2>}
      <Dropzone onDrop={onImageDrop.bind(this)} multiple={false}>
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {videoUrl !== null && (
                <video width="100%" height="300px" controls>
                  <source src={videoUrl} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              {videoUrl === null && editable && !loading && (
                <Button type="button">Lataa video</Button>
              )}
            </div>
          );
        }}
      </Dropzone>
    </VideoContainer>
  );
};

VideoLoader.defaultProps = {
  tag: "string",
  video: {},
  onSuccess: null,
  onError: null,
  editable: true,
};

export default VideoLoader;
