import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "shards-react";

import { AuthContext } from "../_context/auth-context";
import { Button, TeamBody } from "../components/elements";
import FormRow from "../components/FormRow";
import LoadingIndicator from "../components/LoadingIndicator";
import { getRequestById } from "../Data";
import { isUserAdmin } from "../utils";
import CompleteAnalysis from "./CompleteAnalysis";
import RequestAnalysis from "./RequestAnalysis";

interface RequestData {
  description: string;
  analysis: string;
  user: string;
  created: string;
  ready: boolean;
}

interface Media {
  front: {};
  side: {};
  analysis: {};
}

const Analysis = ({ match }) => {
  const history = useHistory();
  const { loadingAuthState, user } = useContext(AuthContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const [values, setValues] = useState({
    description: "",
    analysis: "",
    user: null,
    created: null,
    ready: false,
  } as RequestData);
  const [media, setMedia] = useState({
    front: {},
    side: {},
    analysis: {},
  } as Media);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const fetchRequest = async () => {
      const adminUser = isUserAdmin(user);
      setIsAdmin(adminUser);

      const result = await getRequestById(match.params.id);

      setValues({ ...result, id: match.params.id });
      setMedia(result.media);
    };

    if (match.params.id && user) {
      setEditing(true);
      fetchRequest();
    }
  }, [user]);

  if (loadingAuthState) {
    return <LoadingIndicator />;
  }

  const handleChange = (event: any) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <TeamBody />
      {!isAdmin && (
        <RequestAnalysis
          values={values}
          media={media}
          setError={setError}
          handleChange={handleChange}
          setMedia={setMedia}
          editing={editing}
        />
      )}
      {isAdmin && (
        <CompleteAnalysis
          values={values}
          media={media}
          setError={setError}
          handleChange={handleChange}
          setMedia={setMedia}
          editing={editing}
        />
      )}
      <FormRow>
        <Button onClick={(e) => history.push("/")}>Alkuun</Button>
      </FormRow>
      {error !== null && <Alert theme="danger">{error}</Alert>}
    </div>
  );
};
export default Analysis;
