import firebase from "firebase/app";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Form } from "shards-react";

import { AuthContext } from "../_context/auth-context";
import { Button, FormTextarea, H1, H2, P } from "../components/elements";
import FormRow from "../components/FormRow";
import VideoLoader from "../components/Video";
import { editRequest } from "../Data";
import { formatDate } from "../utils";

type CompleteAnalysis = {
  values: Object;
  media: Object;
  setError: Function;
  handleChange: Function;
  setMedia: Function;
  editing: Boolean;
};

const CompleteAnalysis = ({
  values,
  media,
  setError,
  setMedia,
  handleChange,
  editing,
}) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const request = {
      ...values,
      media,
      modified: firebase.firestore.Timestamp.now(),
      ready: true,
    };

    editRequest(values.id, request)
      .then(function (docRef) {
        history.push(`/`);
      })
      .catch(function (error) {
        setError(error);
      });
  };

  const addMedia = (file) => setMedia({ ...media, [file.tag]: file });

  return (
    <Form onSubmit={handleSubmit}>
      <FormRow>
        <H1>Svingianalyysi</H1>
        <Container>
          Käyttäjä: {values.email && values.email}
          <br />
          Lähetetty: {values.created && formatDate(values.created)}
        </Container>
      </FormRow>

      <FormRow>
        <H2>Video edestä</H2>
        <VideoLoader
          tag="front"
          video={media.front}
          onSuccess={addMedia}
          onError={setError}
          editable={false}
        />
      </FormRow>
      <FormRow>
        <H2>Video sivusta</H2>
        <VideoLoader
          tag="side"
          video={media.side}
          onSuccess={addMedia}
          onError={setError}
          editable={false}
        />
      </FormRow>
      <FormRow>
        <div>
          <H2>Ongelman kuvaus</H2>
          <p>{values.description}</p>
        </div>
      </FormRow>
      <FormRow>
        <H2>Videoanalyysi 1</H2>
        <VideoLoader
          tag="analysis1"
          video={media.analysis1}
          onSuccess={addMedia}
          onError={setError}
        />
      </FormRow>
      {media.analysis1 && (
        <FormRow>
          <H2>Videoanalyysi 2</H2>
          <VideoLoader
            tag="analysis2"
            video={media.analysis2}
            onSuccess={addMedia}
            onError={setError}
          />
        </FormRow>
      )}
      {media.analysis2 && (
        <FormRow>
          <H2>Videoanalyysi 3</H2>
          <VideoLoader
            tag="analysis3"
            video={media.analysis3}
            onSuccess={addMedia}
            onError={setError}
          />
        </FormRow>
      )}
      {media.analysis3 && (
        <FormRow>
          <H2>Videoanalyysi 4</H2>
          <VideoLoader
            tag="analysis4"
            video={media.analysis4}
            onSuccess={addMedia}
            onError={setError}
          />
        </FormRow>
      )}
      {media.analysis4 && (
        <FormRow>
          <H2>Videoanalyysi 5</H2>
          <VideoLoader
            tag="analysis5"
            video={media.analysis5}
            onSuccess={addMedia}
            onError={setError}
          />
        </FormRow>
      )}
      <FormRow>
        <H2>Analyysi</H2>
        <FormTextarea
          name="analysis"
          onChange={handleChange}
          value={values.analysis}
          rows="5"
        />
      </FormRow>
      <FormRow>
        <Button>Lähetä analyysi</Button>
      </FormRow>
    </Form>
  );
};
export default CompleteAnalysis;
