import React from "react";
import { Link } from "react-router-dom";
import { Button, FormInput, FormTextarea } from "shards-react";
import styled, { createGlobalStyle } from "styled-components";

import teamLogo from "../static/img/teamlogo.png";
import teamPicture from "../static/img/teamPictureTall.jpg";

const josefin = `"Josefin Sans", sans-serif;`;

const H1 = styled.h1`
  color: ${({ color }) => color || "#fff"};
  text-align: center;
  margin: 4rem 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 3rem;
`;

const H2 = styled.h2`
  color: ${({ color }) => color || "#fff"};
  font-family: "Josefin Sans", sans-serif;
  font-size: 2rem;
  margin-top: 2rem;
`;

const P = styled.p`
  color: ${({ color }) => color || "#fff"};
  font-size: 1.8rem;
  line-height: 1.4em;
  font-family: ${josefin};
`;

const StyledButton = styled(Button)`
  font-family: ${josefin};
  font-size: 1.25rem;
`;

const StyledFormInput = styled(FormInput)`
  font-family: ${josefin};
  font-size: 1.25rem;
`;

const StyledFormTextarea = styled(FormTextarea)`
  font-family: ${josefin};
  font-size: 1.25rem;
`;

const LoginBody = createGlobalStyle`
  body {
    color: #fff;
    padding-bottom: 2rem;
    background: url(${teamLogo}) top center no-repeat;
    background-size: cover;
  }
`;

const TeamBody = createGlobalStyle`
  body {
    color: #fff;
    padding-bottom: 2rem;
    background: url(${teamPicture}) top center no-repeat;
  }
`;

export {
  H1,
  H2,
  P,
  LoginBody,
  TeamBody,
  StyledButton as Button,
  StyledFormInput as FormInput,
  StyledFormTextarea as FormTextarea,
};
